<template>
    <div v-loading="loading">
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div class='wrapper'>
            <div class='publicServiceCondition'>
                <el-row class='publiceCommon'>
                    <el-col :span="2" class='title'>作物类别</el-col>
                    <el-col :span="22">
                        <span v-for='(itm, idx) in conditionList' :key='idx+"i"' :class='selectGermplasmMenu==idx?"active":""'  class='typeName' @click='onSelectCon(idx, itm.id)'>{{ itm.name }}</span>
                    </el-col>
                </el-row>
                <el-row class='publiceCommon' >
                    <el-col :span="2" class='title'>作物品类</el-col>
                    <el-col :span="19" >
                        <div ref='cropList' class='cropListContent' :class='className'>
                            <span v-for='(itm, idx) in cropList' :key='idx+"c"' :class='selectCropList==idx?"active":""'  class='typeName' @click='onSelectCropList(idx, itm.id)'>{{ itm.name }}</span>
                        </div>
                    </el-col>
                    <el-col :span="2" v-if='isShowMore'>
                        <div class='tip' @click='onTip'>{{tip}}<i :class="className?'el-icon-arrow-up':'el-icon-arrow-down'"></i></div>
                    </el-col>
                </el-row>
            </div>
            <div class="clearfix">
                <div class='leftContent fl' v-if='germplasmMenuList.total > 0'>
                    <div class='result'>共有 <span>{{germplasmMenuList.total}}</span> 个相关结果</div>
                    <router-link tag="div" :to='{path: "/germplasmDetail", query:{id: item.id}}'  v-for='(item, index) in germplasmMenuList.datas' :key='index+ "g"' class='germplasmMenuList'>
                        <div class='scientificName' v-if='germplasmMenuList.datas.length > 0'>{{germplasmMenuList.datas[0].data7}}</div>
                        <div class='field clearfix'>
                            <div  v-for='(itm, idx) in germplasmMenuList.dictionaries' :key='idx+ "d"'>
                                <span v-if='idx < 4'>{{itm.fieldName}}：{{item[itm.data] ? item[itm.data]: '无'}}</span> 
                            </div>
                        </div>
                    </router-link>
                    <pagination v-if='page.total > param.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
                </div>
                <div v-else class='fl nodata'>
                    <no-data></no-data>
                </div>
                <div class='fl rightContent'>
                    <hot-demand></hot-demand>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import pagination from '@/component/pagination/index.vue';
import noData from '@/component/noData/index.vue';
import hotDemand from '@/component/hotDemand/index.vue';

export default {
    components: {
        nfBreadcrumb,
        pagination,
        noData,
        hotDemand
    },
    data() {
        return {
             breadList: [{
                name: '数据查询'
            }, {
                name: '种质资源数据'
            }],
            conditionList: [], // 大类
            selectGermplasmMenu: 0,
            cropList: [], // 小类
            selectCropList: 0,
            germplasmMenuList: '',
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 8
            },
            param: {
                classId: '',
                pageSize: 8,
                pageNum: 1
            },
            tip: '收起',
            className: 'more',
            height: '',
            isShowMore: false,
            loading: true
        };
    },
    mounted() {
        this.germplasmMenu();
    },
    watch: {
        height: {
            handler(val) {
                if (val > 68) {
                    this.isShowMore = true;
                } else {
                    this.isShowMore = false;
                }
            },
            deep: true
        }
    },
    methods: {
        async onSelectCon(idx, id) {
            this.selectGermplasmMenu = idx;
            this.selectCropList = 0;
            await this.getCropList(id);
            if (this.cropList.length > 0) {
                this.page.currentPage = 1;
                this.param.classId = this.cropList[0].id;
                await this.getGermplasmMenuList();
            }
        },
        onSelectCropList(idx, id) {
            this.selectCropList = idx;
            this.page.currentPage = 1;
            this.param.classId = id;
            this.getGermplasmMenuList();
        },
        onTip() {
            if (this.className) {
                this.className = '';
                this.tip = '更多';
            } else {
                this.className = 'more';
                this.tip = '收起';
            }
        },
        // 获取大类
        async germplasmMenu(index) {
            this.loading = true;
            const api = this.$fetchApi.germplasmMenu.api;
            const data = await this.$fetchData.fetchGet({}, api, 'json');
            this.loading = false;
            if (data.code === '200') {
                this.conditionList = data.result;
                if (data.result.length > 0) {
                    await this.getCropList(data.result[0].id);
                    if (this.cropList.length > 0) {
                        this.page.currentPage = 1;
                        this.param.classId = this.cropList[0].id;
                        await this.getGermplasmMenuList();
                    }
                }
            }
        },
        /** 获取作物品类 */
        async getCropList(id) {
            const params = {
                classId: id // 圈子ID
            };
            const api = this.$fetchApi.germplasmList.api;
            const data = await this.$fetchData.fetchPost(params, api, 'json');
            if (data.code === '200') {
                this.cropList = data.result;
            }
            this.$nextTick(() => {
                this.height =  this.$refs.cropList.offsetHeight;
            });
        },
        /** 获取作物品类 */
        async getGermplasmMenuList() {
            this.param.pageNum = this.page.currentPage;
            const api = this.$fetchApi.germplasmMenuList.api;
            this.className = 'more';
            this.tip = '收起';
            const data = await this.$fetchData.fetchPost(this.param, api, 'json');
            if (data.code === '200') {
                this.germplasmMenuList = data.result;
                this.page.total = data.result.total;
            }
        },
        pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
            this.getGermplasmMenuList(); //改变页码，重新渲染页面
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/selectCondition';
</style>